import React, {useEffect} from 'react';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import {_get, useInjectStores, buildColDef} from 'utils/utils';
import {Dropdown, LoadingSpinner, Table} from 'components/UI';
import {useHistory, useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Wrapper} from 'styles/styledComponents';
import {widgetService} from 'services';

const AvsFailure = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const {rootStore, dataManagementStore, widgetStore, utilsStore, userStore} = useInjectStores();
    const toDateFormatter = utilsStore.toDateFormatter;
    const availableReports = _get(userStore, 'user.currentClient.permissions.reps_allowed_sname', '').split(',');
    let authorized = false;
    if(availableReports.includes(location.pathname.split('/').at(-1))) {
        authorized = true;
    }
    const widgetModel = widgetStore.avsFailure,
        paginatorModel = widgetModel.paginatorModel;

    const orderByOptions = [
        {key: 1, text: 'Customer ID', value: 'customer_id'},
        {key: 2, text: 'Customer Name', value: 'customer_name'}
    ];

    const store = useLocalObservable(() => ({
        fileName: 'avs-failure-report',
        loading: false,
        api: null,
        gridColumnApi: null,
        orderByOptions,
        dataManagementStore,
        widgetModel,
        paginatorModel,
        rowData: [],
        columnDefs: [],
        txnCount: 0,
        exportOptions: [
            {key: 1, text: 'EXCEL', value: 'excel'},
            {key: 2, text: 'CSV', value: 'csv'},
        ],
        exportType: 'excel',
        setExportType: (type) => {
            this.exportType = type;
        },
        get gridOptions() {
            return {
                onGridReady: (params) => {
                    this.api = params.api;
                    this.gridColumnApi = params.columnApi;
                },
                enableCharts: true,
                enableRangeSelection: true,
                groupMultiAutoColumn: true,
                rowHeight: 33,
                headerHeight: 33,
                reactNext: true,
                sideBar: {
                    position: 'left',
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                        },
                    ],
                },
                defaultColDef: {
                    sortable: true,
                    filter: true,
                    resizable: true
                },
                columnDefs: store.columnDefs,
                overlayLoadingTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
                overlayNoRowsTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
            };
        },

        setRowData(txnsData) {
            const {status, data, error} = txnsData;
            if (status === 'loading') this.loading = true;
            if (error) {
                this.error = error;
                this.loading = false;
            }
            if (data) {
                const widths = [175, 155, 175, 130, 135, 185, 120, 140, 140, 130, 140, 120, 150, 100, 110, 130];
                store.columnDefs = data?.data?.results.length ? Object.keys(data.data.results[0]).map((key, i) => {
                    // const autoWidth = data.data.results[0][key].length >= key.length ? data.data.results[0][key].length * 11 : key.length * 11;
                    return buildColDef(utilsStore, key, widths[i]);
                }) : [];
                this.rowData = data.data.results.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn
                    };
                });
                this.txnCount = data.data.count;
                this.loading = false;
            }
        },
        async getData() {
            this.loading = true;

            try {
                if (this.api) {
                    this.api.showLoadingOverlay();
                }
                const res = await widgetModel.fetchWidgetData(widgetService.reportAvsFailure);
                this.widgetData = res.data.data;
                this.rowData = this.widgetData.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn
                    };
                });
                this.txnCount = this.widgetData.length;
            } catch (error) {
                console.error('error', error);
                this.widgetData = [];
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    rootStore.userStore.logout(history, location);
                }
            }
            this.loading = false;
            if (this.api && !this.loading) {
                this.api.hideOverlay();
            }
            if (this.api && !this.loading && this.rowData.length === 0) {
                this.api.showNoRowsOverlay();
            }
        },
    }));

    const txnsData = authorized ? useQuery(
        ['chargebackAbuse', widgetModel.query],
        !!dataManagementStore.newQuery ? () => widgetModel.fetchWidgetData(widgetService.reportAvsFailure) : () => {
        },
        {
            refetchOnWindowFocus: false,
            enabled: !!dataManagementStore.newQuery
        }
    ) : null;

    useEffect(() => {
        if (txnsData) {
            store.setRowData(txnsData);
        }
    }, [txnsData]);

    useEffect(() => {
        paginatorModel.setPageNumber(1);
    }, [dataManagementStore.newQuery]);

  return (
    <Observer>
      {() => (
        <>
          {!authorized ? (
            <Wrapper>
                <div style={{color: 'white', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    You are not authorized to view this report.
                </div>
            </Wrapper>
          ) :
          store.loading ? (
            <Wrapper>
              <LoadingSpinner size={'200px'} />
            </Wrapper>
          ) : store.error ? (
              <div>there was an error</div>
            ) : // <Error error={store.error} />
            store.rowData ? (
              <Table
                store={store}
                fetchTableData={store.getData}
                rowData={store.rowData}
                loading={store.loading}
                /**export**/
                initiateTxnsExport={store.widgetModel.initiateTxnsExport}
                exportInfo={store.widgetModel.exportInfo}
                /** Paginator-Specific Functions **/
                txnCount={store.rowData.length}
              paginator={paginatorModel}
              /** OrderBy Properties **/
              addOrderBy={store.widgetModel.addOrderBy}
              orderByArray={store.widgetModel.orderByModel}
              orderByOptions={store.orderByOptions}
              resetOrderBy={store.widgetModel.resetOrderBy}
            />
          ) : null}
        </>
      )}
    </Observer>
  );
});

export default AvsFailure;
