import React, {useEffect} from 'react';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import {_get, useGetWidgetData, useInjectStores, buildColDef} from 'utils/utils';
import {Dropdown, LoadingSpinner, Table} from 'components/UI';
import {useHistory, useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Wrapper} from 'styles/styledComponents';
import {widgetService} from 'services';
import Moment from "moment/moment";
import styled from "styled-components/macro";

const CardExpRptHistoric = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const {rootStore, dataManagementStore, widgetStore, userStore, utilsStore} = useInjectStores();

    const widgetModel = widgetStore.cardExpHistDetails,
        paginatorModel = widgetModel.paginatorModel;

    const orderByOptions = [
        {key: 1, text: 'Cust Name', value: 'custName'},
        {key: 2, text: 'Invoice Num', value: 'invNum'},
        {key: 3, text: 'Decl Rsn', value: 'respCodeSname'},
        {key: 4, text: 'Txn Date', value: 'txnDt'},
    ];

    const availableReports = _get(userStore, 'user.currentClient.permissions.reps_allowed_sname', '').split(',');
    let authorized = false;
    if(availableReports.includes(location.pathname.split('/').at(-1))) {
        authorized = true;
    }

    const store = useLocalObservable(() => ({
        fileName: 'card-exp-rpt',
        loading: false,
        api: null,
        gridColumnApi: null,
        orderByOptions,
        dataManagementStore,
        widgetModel,
        paginatorModel,
        rowData: [],
        txnCount: 0,
        columnDefs: [],
        historicData: null,
        // historicExpDate: null,
        selectedOption: widgetModel.date,
        exportOptions: [
            {key: 1, text: 'EXCEL', value: 'excel'},
            {key: 2, text: 'CSV', value: 'csv'},
        ],
        exportType: 'excel',
        setExportType: (type) => {
            this.exportType = type;
        },
        get historicDate() {
            return store.selectedOption
        },
        selectOption(val) {
            this.selectedOption = val;
            widgetModel.setDate(val);
        },
        get dropdownOptions() {
            if (this.historicData?.length > 0) {
                return this.historicData.map(({txn_dt_eom}, index) => {
                    return {
                        key: index,
                        text: `${Moment(txn_dt_eom).format('YYYY')}: ${Moment(txn_dt_eom).format('MMMM')}`,
                        value: txn_dt_eom
                    };
                });
            }
            return []
        },
        get gridOptions() {
            return {
                onGridReady: (params) => {
                    this.api = params.api;
                    this.gridColumnApi = params.columnApi;
                },
                enableCharts: true,
                enableRangeSelection: true,
                groupMultiAutoColumn: true,
                rowHeight: 33,
                headerHeight: 33,
                reactNext: true,
                sideBar: {
                    position: 'left',
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                        },
                    ],
                },
                defaultColDef: {
                    sortable: false,
                    filter: true,
                    resizable: true,
                },
                columnDefs: store.columnDefs,
                overlayLoadingTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
                overlayNoRowsTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
            };
        },

        setRowData(txnsData) {
            const {status, data, error} = txnsData;
            if (status === 'loading') this.loading = true;
            if (error) {
                this.error = error;
                this.loading = false;
            }
            if (data) {
                const widths = [135, 160, 170, 140, 100, 160, 160];
                store.columnDefs = data?.data?.values.length ? Object.keys(data.data.values[0]).map((key, i) => {
                    // const autoWidth = data.data.values[0][key].length >= key.length ? data.data.values[0][key].length * 11 : key.length * 11;
                    return buildColDef(utilsStore, key, widths[i]);
                }) : [];
                if (_get(data,'data.history')) {
                    this.historicData = data.data.history;
                }
                this.rowData = data.data.values.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn
                    };
                });
                this.txnCount = data.data.count;
                this.loading = false;
            }
        },
        async getData() {
            this.loading = true;

            try {
                if (this.api) {
                    this.api.showLoadingOverlay();
                }

                const res = await widgetModel.fetchWidgetData(widgetService.reportCardExpired);
                this.widgetData = res.data.data;
                this.rowData = this.widgetData.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn
                    };
                });
                this.txnCount = this.widgetData.length;
            } catch (error) {
                console.error('error', error);
                this.widgetData = [];
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    rootStore.userStore.logout(history, location);
                }
            }
            this.loading = false;
            if (this.api && !this.loading) {
                this.api.hideOverlay();
            }
            if (this.api && !this.loading && this.rowData.length === 0) {
                this.api.showNoRowsOverlay();
            }
        },
    }));

    const txnsData = authorized ? useQuery(
        ['reportCardExpiredHistoric', widgetModel.query],
        !!dataManagementStore.newQuery ? () => widgetModel.fetchWidgetData(widgetService.reportCardExpiredHistoric, store.historicDate) : () => {
        },
        {
            refetchOnWindowFocus: false,
            enabled: !!dataManagementStore.newQuery
        }
    ) : null;

    useEffect(() => {
        if (txnsData) {
            store.setRowData(txnsData);
        }
    }, [txnsData]);

    useEffect(() => {
        paginatorModel.setPageNumber(1);
    }, [dataManagementStore.newQuery]);

    return (
        <Observer>
            {() => (
                <>
                    {!authorized ? (
                        <Wrapper>
                            <div style={{color: 'white', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                You are not authorized to view this report.
                            </div>
                        </Wrapper>
                    ) :
                    store.loading ? (
                        <Wrapper>
                            <LoadingSpinner size={'200px'}/>
                        </Wrapper>
                    ) : store.error ? (
                        <div>there was an error</div>
                    ) : store.rowData ? (
                        <Wrapper1>
                            <div className={'one'} style={{
                                width: 'calc(100% - 40px)',
                                margin: '3px 0',
                                // justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                <Dropdown
                                    id="historical"
                                    options={store.dropdownOptions}
                                    value={widgetModel.date}
                                    onChange={store.selectOption}
                                    placeholder="Select Month"
                                    width={'140px'}
                                    height={'20px'}
                                />
                                {/*&nbsp; &nbsp;*/}
                                {/*<div>Historical</div>*/}
                            </div>
                            <div className="two">
                                <Table
                                    store={store}
                                    fetchTableData={store.getData}
                                    rowData={store.rowData}
                                    loading={store.loading}
                                    /**export**/
                                    initiateTxnsExport={store.widgetModel.initiateTxnsExport}
                                    exportInfo={store.widgetModel.exportInfo}
                                    /** Paginator-Specific Functions **/
                                    txnCount={store.rowData.length}
                                    paginator={paginatorModel}
                                    /** OrderBy Properties **/
                                    addOrderBy={store.widgetModel.addOrderBy}
                                    orderByArray={store.widgetModel.orderByModel}
                                    orderByOptions={store.orderByOptions}
                                    resetOrderBy={store.widgetModel.resetOrderBy}
                                />
                            </div>
                        </Wrapper1>
                    ) : null}
                </>
            )}
        </Observer>
    );
});

export default CardExpRptHistoric;

const Wrapper1 = styled.div`
  display: grid;

  .one {
    grid-area: one;
  }

  .two {
    grid-area: two;
  }

  grid-template-areas: 
          'one'
          'two';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  //grid-gap: 5px;
  height: 100%;
`;
