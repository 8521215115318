import React, { useEffect } from 'react';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Notes, Table } from 'components/UI';
import { autorun } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';
import { _get, useInjectStores, buildColDef } from 'utils/utils';
import { widgetService } from 'services';

const IsoCommissions = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { widgetStore, dataManagementStore, uiStore, userStore, utilsStore } = useInjectStores();
  const widgetModel = widgetStore.ssTxnsWidgetModel,
    paginatorModel = widgetModel.paginatorModel;

  const orderByOptions = [
    { key: 1, text: 'Cust Name', value: 'custName' },
    { key: 2, text: 'Invoice Num', value: 'invNum' },
    { key: 3, text: 'Txn Type', value: 'txnType' },
    { key: 4, text: 'Txn Status', value: 'txnStatus' },
    { key: 5, text: 'Txn Date', value: 'txnDt' },
  ];

  const initialData = { txns: [], count: 0 };

  const store = useLocalStore(() => ({
    fileName: 'commissions-report',
    storageKey: 'txn-details-state',
    loading: false,
    columnDefs: [],
    widgetData: initialData,
    rowData: [],
    txnCount: 0,
    async getData() {
      store.loading = true;
      try {
        const res = await widgetModel.fetchWidgetData(widgetService.isoCommissionsReport);

        store.widgetData = res.data;

        store.columnDefs = Object.keys(store.widgetData.values[0]).map(key => {
          let autoWidth = store.widgetData.values[0][key].length >= 15 ? store.widgetData.values[0][key].length * 10.5 : store.widgetData.values[0][key].length * 12;
          return buildColDef(utilsStore, key, autoWidth < 115 ? 115 : autoWidth);
        });

        store.rowData = store.widgetData.values.map((txn, index) => {
            txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
            return {
                ...txn
            };
        });
      
        store.txnCount = store.widgetData.count;
      } catch (error) {
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
    orderByOptions,
    dataManagementStore,
    widgetModel,
    paginatorModel,
    api: null,
    columnApi: null,
    saveColumnState() {
      let savedState = store.columnApi.getColumnState();
      savedState = JSON.stringify(savedState);
      if (savedState) {
        window.localStorage.setItem('txn-details-state', savedState);
      }
    },
    setColumnState() {
      let savedState = window.localStorage.getItem('txn-details-state');
      savedState = JSON.parse(savedState);
      if (savedState && store.columnApi) {
        store.columnApi.applyColumnState({ state: savedState, applyOrder: true });
      }
    },
    resetColumnState() {
      window.localStorage.removeItem('txn-details-state');
      store.columnApi.resetColumnState();
    },
    get gridOptions() {
      return {
        enableCharts: true,
        enableRangeSelection: true,
        groupMultiAutoColumn: true,
        rowSelection: 'multiple',
        rowHeight: 33,
        headerHeight: 33,
        sideBar: {
          position: 'left',
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
            },
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            },
          ],
        },
        defaultColDef: {
          sortable: true,
          filter: true,
          resizable: true,
          suppressMenu: true
        },
        columnDefs: store.columnDefs,

        onGridReady(params) {
          store.api = params.api;
          store.columnApi = params.columnApi;
          store.setColumnState();
        },
        onFirstDataRendered(params) {
          setTimeout(params.columnApi.autoSizeColumns(['details', 'Index']));
        },
        overlayLoadingTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
        overlayNoRowsTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
      };
    },
  }));

  useEffect(
    () =>
      autorun(async () => {
        if (dataManagementStore.newQuery) {
          paginatorModel.setPageNumber(1);
          await store.getData();
        }
      }),
    []
  );
  
  return (
    <Observer>
      {() => (
        <TableWrapper>
          <Table
            store={store}
            fetchTableData={store.getData}
            rowData={store.rowData}
            loading={store.loading}
            /**export**/
            initiateTxnsExport={store.widgetModel.initiateTxnsExport}
            exportInfo={store.widgetModel.exportInfo}
            /** Paginator-Specific Functions **/
            txnCount={store.txnCount}
            paginator={paginatorModel}
            enableBrowserTooltips={true}
            /** OrderBy Properties **/
            addOrderBy={store.widgetModel.addOrderBy}
            orderByArray={store.widgetModel.orderByModel}
            orderByOptions={store.orderByOptions}
            resetOrderBy={store.widgetModel.resetOrderBy}
          />
        </TableWrapper>
      )}
    </Observer>
  );
});



export default IsoCommissions;

const TableWrapper = styled.div`
height: 98%;
margin-left: 5px;
margin-right: 5px;
`;