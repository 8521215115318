import React, {useEffect} from 'react';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import {_get, useInjectStores, buildColDef, useGetWidgetData, _toNumber} from 'utils/utils';
import {Dropdown, LoadingSpinner, Table, Error} from 'components/UI';
import {useHistory, useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';
import { AgGridReact } from 'ag-grid-react';
import { TableHeaderButton } from 'components/UI/Table/TableHeader/StyledResources/StyledTableHeader';
import { TableWrapper, TableHeader } from '../StyledTable';
import styled from 'styled-components/macro';
import {widgetService} from 'services';

const IsoJournals = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const {rootStore, dataManagementStore, widgetStore, utilsStore, userStore} = useInjectStores();
    const toDateFormatter = utilsStore.toDateFormatter;
    const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
    const widgetModel = widgetStore.avsFailure,
        paginatorModel = widgetModel.paginatorModel;

    const orderByOptions = [
        {key: 1, text: 'Customer ID', value: 'customer_id'},
        {key: 2, text: 'Customer Name', value: 'customer_name'}
    ];

    function cellStyle(params) {
        if (params.value < 0) {
          return { color: '#FF2B49' };
        } else {
          // return { color: 'rgb(69, 232, 133)' };
        }
      }

    const store = useLocalObservable(() => ({
        fileName: 'journals',
        loading: false,
        api: null,
        gridColumnApi: null,
        orderByOptions,
        dataManagementStore,
        widgetModel,
        paginatorModel,
        rowData: [],
        totalDR: 0,
        totalCR: 0,
        columnDefs: [{
            headerName: 'GL ACCT: DESCRIPTION',
            field: 'gl_acct_desc',
            chartDataType: 'series',
            width: 300
          },
          {
            headerName: 'DR',
            field: 'amt_by_gl_acct',
            valueFormatter: (obj) => {
                if(obj.data.gl_acct_desc === 'TOTAL') return toCurrencyFormatter({value: store.totalDR});
                const parsedValue = parseFloat(obj.value);
                if(parsedValue >= 0) {
                    store.totalDR += parsedValue;
                    return toCurrencyFormatter({value: parsedValue});
                }
                return toCurrencyFormatter({value: 0.00});
            },
            type: 'rightAligned',
            chartDataType: 'series',
            width: 125
          },
          {
            headerName: 'CR',
            field: 'amt_by_gl_acct',
            valueFormatter: (obj) => {
                if(obj.data.gl_acct_desc === 'TOTAL') return toCurrencyFormatter({value: store.totalCR});
                const parsedValue = parseFloat(obj.value);
                if(parsedValue <= 0) {
                    store.totalCR += parsedValue;
                    return toCurrencyFormatter({value: parsedValue});
                }
                return toCurrencyFormatter({value: 0.00});
            },
            type: 'rightAligned',
            chartDataType: 'series',
            width: 125
          }],
        txnCount: 0,
        exportOptions: [
            {key: 1, text: 'EXCEL', value: 'excel'},
            {key: 2, text: 'CSV', value: 'csv'},
        ],
        exportType: 'excel',
        setExportType: (type) => {
            this.exportType = type;
        },
        get gridOptions() {
            return {
                onGridReady: (params) => {
                    this.api = params.api;
                    this.gridColumnApi = params.columnApi;
                },
                enableCharts: true,
                enableRangeSelection: true,
                groupMultiAutoColumn: true,
                rowHeight: 33,
                headerHeight: 33,
                reactNext: true,
                sideBar: {
                    position: 'left',
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                        },
                    ],
                },
                defaultColDef: {
                    sortable: true,
                    filter: true,
                    resizable: true
                },
                columnDefs: store.columnDefs,
                overlayLoadingTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
                overlayNoRowsTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
            };
        },

        setRowData(txnsData) {
            const {status, data, error} = txnsData;
            if (status === 'loading') this.loading = true;
            if (error) {
                this.error = error;
                this.loading = false;
            }
            if (data) {
                const widths = [340, 150];
				if(data?.data?.values.length){
					store.columnDefs = Object.keys(data?.data?.values[0]).reverse().map((key, i) => {
						return buildColDef(utilsStore, key, widths[i]);
					});
				};
                this.rowData = data.data.values.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn
                    };
                });
                this.txnCount = data.data.count || data.data.values.length;
                this.loading = false;
            }
        },
        async getData() {
            this.loading = true;

            try {
                if (this.api) {
                    this.api.showLoadingOverlay();
                }
                const res = await widgetModel.fetchWidgetData(widgetService.isoJournals);
                this.widgetData = res.data.data;
                this.rowData = this.widgetData.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn
                    };
                });
                this.txnCount = this.widgetData.length;
            } catch (error) {
                console.error('error', error);
                this.widgetData = [];
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    rootStore.userStore.logout(history, location);
                }
            }
            this.loading = false;
            if (this.api && !this.loading) {
                this.api.hideOverlay();
            }
            if (this.api && !this.loading && this.rowData.length === 0) {
                this.api.showNoRowsOverlay();
            }
        },
    }));

    let {status: statusIso, data: dataIso, error: errorCash} = useGetWidgetData(
        {name: 'isoJournal', query: widgetService.isoJournals},
        'data',
        dataManagementStore?.newQuery
    );

    const getRowStyle = params => {
        if (params.node.rowIndex === params.api.getDisplayedRowCount() - 1) {
            return { borderTop: '1px solid rgba(255, 255, 255, 0.5)', backgroundColor: 'rgb(4, 28, 47)', fontWeight: 'bold' };
        }
        return null;
    };

    if(dataIso?.values.length) {
        if(dataIso.values[dataIso.values.length - 1].gl_acct_desc !== 'TOTAL') {
            dataIso.values.push({
                amt_by_gl_acct: "0.00",
                gl_acct_desc: "TOTAL"
            });
        }
    }

  return (
    <Observer>
      {() => (
        <TableWrapper height="100%">
        <TableHeaderWrapper padding="6px">
          <TableHeader height="auto" padding="0" margin="auto">
            Journal - Accrual Basis
          </TableHeader>
          <Dropdown
            className="exportDropdown"
            caretRight={10}
            options={store.exportOptions}
            onChange={store.setExportType}
            value={store.exportType}
            width="80px"
            height="25px"
            margin="0"
            border
          />
          <TableHeaderButton
            height="27px"
            onClick={() => {
              if (store.exportType === 'csv') {
                store.api.exportDataAsCsv({ fileName: store.fileName });
              } else {
                store.api.exportDataAsExcel({ fileName: store.fileName });
              }
            }}
          >
            Export
          </TableHeaderButton>
        </TableHeaderWrapper>
        <Wrapper>
          <>
            {statusIso === 'loading' ? (
              <LoadingSpinner size={'200px'} />
            ) : store.status === 'error' ? (
              <Error error={errorCash} />
            ) : (
              <div
                id="myGrid"
                className={'ag-theme-alpine-dark'}
                style={{
                  height: '100%',
                  minHeight: '275px',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <AgGridReact rowData={dataIso?.values || []} gridOptions={store.gridOptions} onGridReady={store.onGridReady} getRowStyle={getRowStyle}/>
              </div>
            )}
          </>
        </Wrapper>
      </TableWrapper>
      )}
    </Observer>
  );
});

const TableHeaderWrapper = styled.div`
  padding: ${({ padding }) => padding || ''};
  display: flex;
`;
const Wrapper = styled.div`
  height: 100%;
  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    --ag-background-color: transparent;
    --ag-odd-row-background-color: transparent;
    --ag-border-color: rgba(255, 255, 255, 0.5);
    --ag-header-background-color: #041c2f;
    .ag-header-row {
      height: 30px;
    }
    .ag-cell {
      line-height: 30px;
      font-size: 12px;
    }
    .ag-header-cell-label {
      display: flex;
      justify-content: center;
    }
    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }
  }
`;

export default IsoJournals;
