import React, {useEffect, useMemo, useState} from 'react';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import {_get, useInjectStores, buildColDef} from 'utils/utils';
import {Dropdown, LoadingSpinner, Table} from 'components/UI';
import TableHeader from '../../UI/Table/TableHeader/TableHeader';
import {useHistory, useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Wrapper} from 'styles/styledComponents';
import styled from 'styled-components/macro';
import {AgGridReact} from 'ag-grid-react';
import {widgetService} from 'services';

const DeclineAnalysis = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const {rootStore, dataManagementStore, widgetStore, utilsStore, userStore} = useInjectStores();
    const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
    const widgetModel = widgetStore.declineAnalysis,
        paginatorModel = widgetModel.paginatorModel

    const orderByOptions = [
        {key: 1, text: 'Customer ID', value: 'customer_id'},
        {key: 2, text: 'Customer Name', value: 'customer_name'}
    ];

    const availableReports = _get(userStore, 'user.currentClient.permissions.reps_allowed_sname', '').split(',');
    let authorized = false;
    if(availableReports.includes(location.pathname.split('/').at(-1))) {
        authorized = true;
    }

    const store = useLocalObservable(() => ({
        fileName: 'decline-analysis-by-card-report',
        loading: false,
        api: null,
        gridColumnApi: null,
        orderByOptions,
        dataManagementStore,
        widgetModel,
        paginatorModel,
        rowData: [],
        columnDefs: [
            { field: 'cust_nm', headerName: 'Customer Name', width: 160 },
            { field: 'et_txn_stat_expl', headerName: 'ET Txn Status', width: 180 },
            { field: 'decl_cnt', headerName: 'Decline Count', valueFormatter: (value) => utilsStore.formatNumber(value.value), cellStyle: {'text-align': 'end'}, width: 145 },
            { field: 'sum_decls_amt', headerName: 'Sum of Declines', valueFormatter: utilsStore.toCurrencyFormatterWithUndefined, cellStyle: {'text-align': 'end'}, width: 160 },
          ],
        txnCount: 0,
        exportOptions: [
            {key: 1, text: 'EXCEL', value: 'excel'},
            {key: 2, text: 'CSV', value: 'csv'},
        ],
        exportType: 'excel',
        setExportType: (type) => {
            this.exportType = type;
        },
        get gridOptions() {
            return {
                onGridReady: (params) => {
                    this.api = params.api;
                    this.gridColumnApi = params.columnApi;
                },
                enableCharts: true,
                enableRangeSelection: true,
                groupMultiAutoColumn: true,
                groupDefaultExpanded: 0,
                rowHeight: 33,
                headerHeight: 33,
                reactNext: true,
                sideBar: {
                    position: 'left',
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                        },
                    ],
                },
                defaultColDef: {
                    sortable: true,
                    filter: true,
                    resizable: true
                },
                columnDefs: store.columnDefs,
                overlayLoadingTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
                overlayNoRowsTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
            };
        },

        setRowData(txnsData) {
            const {status, data, error} = txnsData;
            if (status === 'loading') this.loading = true;
            if (error) {
                this.error = error;
                this.loading = false;
            }
            if (data) {
                let currPan = '';
                let count = 0; 

                this.rowData = data.data.results.map((txn, index) => {
                    let tmp = [];
                    if(txn.tot_col === 'TOTALS') {
                        tmp.push(txn.pan_concat);
                        currPan = txn.pan_concat;
                        count = 0;
                    } else {
                        count++;
                        tmp.push(currPan);
                        tmp.push(' '.repeat(count));
                    }
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        groupPath: tmp,
                        ...txn
                    };
                });
                this.txnCount = data.data.count;
                this.loading = false;
            }
        },
        async getData() {
            this.loading = true;

            try {
                if (this.api) {
                    this.api.showLoadingOverlay();
                }
                const res = await widgetModel.fetchWidgetData(widgetService.reportDeclineAnalysis);
                this.widgetData = res.data;
                let currPan = '';
                let count = 0; 
                this.rowData = this.widgetData.results.map((txn, index) => {
                    let tmp = [];
                    if(txn.tot_col === 'TOTALS') {
                        tmp.push(txn.pan_concat);
                        currPan = txn.pan_concat;
                        count = 0;
                    } else {
                        count++;
                        tmp.push(currPan);
                        tmp.push(' '.repeat(count));
                    }
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        groupPath: tmp,
                        ...txn
                    };
                });
                this.txnCount = this.widgetData.length;
            } catch (error) {
                console.error('error', error);
                this.widgetData = [];
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    rootStore.userStore.logout(history, location);
                }
            }
            this.loading = false;
            if (this.api && !this.loading) {
                this.api.hideOverlay();
            }
            if (this.api && !this.loading && this.rowData.length === 0) {
                this.api.showNoRowsOverlay();
            }
        },
    }));
    paginatorModel.setTxnLimit(100);
    const txnsData = authorized ? useQuery(
        ['declineAnalysis', widgetModel.query],
        !!dataManagementStore.newQuery ? () => widgetModel.fetchWidgetData(widgetService.reportDeclineAnalysis) : () => {
        },
        {
            refetchOnWindowFocus: false,
            enabled: !!dataManagementStore.newQuery
        }
    ) : null;

    useEffect(() => {
        if (txnsData) {
            store.setRowData(txnsData);
        }
    }, [txnsData]);

    // useEffect(() => {
    //     paginatorModel.setTxnLimit(100);
    //     paginatorModel.setPageNumber(1);
    // }, [dataManagementStore.newQuery]);

    const autoGroupColumnDef = useMemo(() => {
        return {
          headerName: 'PAN',
          width: 210,
          cellRendererParams: {
            suppressCount: true
          }
        };
      }, []);

    const getDataPath = useMemo(() => {
        return (data) => {
            return data.groupPath;
        };
    }, []);

  return (
    <Observer>
      {() => (
        <>
          {!authorized ? (
                <Wrapper>
                    <div style={{color: 'white', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        You are not authorized to view this report.
                    </div>
                </Wrapper>
            ) :
            store.loading ? (
            <Wrapper>
              <LoadingSpinner size={'200px'} />
            </Wrapper>
          ) : store.error ? (
              <div>there was an error</div>
            ) : // <Error error={store.error} />
            store.rowData ? (
                <>
                  <TableHeader
                    store={store}
                    initiateTxnsExport={store.widgetModel.initiateTxnsExport}
                    exportInfo={store.widgetModel.exportInfo}
                    /** OrderBy **/
                    addOrderBy={store.widgetModel.addOrderBy}
                    orderByArray={store.widgetModel.orderByModel}
                    orderByOptions={store.orderByOptions}
                    resetOrderBy={store.widgetModel.resetOrderBy}
                    loading={store.loading}
                    fetchTableData={store.getData}
                    /*Paginator*/
                    paginator={paginatorModel}
                    txnCount={store.rowData.length}
                    hasSearch={false}
                    
                  />
                  <GridWrapper>
                    <div
                      id="myGrid"
                      className={'ag-theme-alpine-dark'}
                      style={{
                        height: '85vh',
                        position: 'relative',
                        borderRadius: '5px'
                      }}
                    >
                        <AgGridReact
                            gridOptions={store.gridOptions}
                            rowData={store.rowData}
                            columnDefs={store.columnDefs}
                            autoGroupColumnDef={autoGroupColumnDef}
                            treeData={true}
                            groupDefaultExpanded={0}
                            getDataPath={getDataPath}
                        />
                    </div>
                  </GridWrapper>
                </>
            ) : null}
        </>
      )}
    </Observer>
  );
});

const GridWrapper = styled.div`
  height: 100%;
  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);
    --ag-control-panel-background-color: #041c2f;
    --ag-subheader-background-color: #032845;
    .ag-header-row {
      height: 15px;
    }
    .ag-header-cell-label {
        display: flex;
        justify-content: center;
    }
    .ag-row-level-0 {
        background-color: #001329 !important;
    }
    .ag-row-level-1 {
        background-color: #032845 !important;
    }
  }
`;

export default DeclineAnalysis;
